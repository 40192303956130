<template>
  <div class="column pt-0">
    <h2 class="is-size-5 has-text-weight-semibold mb-4">Список CMS</h2>
    <BaseTable
      :data="agents"
      :columns="columns"
      :loading="isLoading"
      :total="total"
      :per-page="perPage"
      :current-page="currentPage"
      @change="fetchAgents"
      @rowClick="onRowClick"
    />
  </div>
</template>


<script>
import BaseTable from "@/components/Base/Table";

export default {
  components: { BaseTable },
  data() {
    return {
      agents: [],
      total: 0,
      isLoading: true,
      currentPage: 1,
      perPage: 10,
      columns: [
        {
          field: "id",
          label: "ID",
          width: "40",
          numeric: true,
        },
        {
          field: "factory.branch.company.name",
          label: "Компания",
          centered: true,
        },
        {
          field: "device_name",
          label: "CMS",
        },
        {
          field: "status",
          label: "Статус",
        },
        {
          field: "actions",
          label: "",
        },
      ],
    };
  },
  mounted() {
    this.fetchAgents();
  },
  methods: {
    async fetchAgents(page) {
      this.currentPage = page || 1
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get(`admin/agents/paginate/${(this.currentPage - 1) * 10}/${this.perPage}`)
        this.agents = data?.data || [];
        this.total = data?.count;
      } catch (e) {
        throw new Error(e)
      }
      this.isLoading = false;
    },
    onRowClick(item) {
      this.$router.push(`/cms-editor/${item.id}`);
    },
  },
};
</script>
